function formatNumber(number, format, locale) {
    // Determine the number of decimal places from the format string
    const decimalPlaces = (format.split('.')[1] || '').length;

    // if locale is 'es' change to 'es-CL'
    if (locale === 'es') {
        locale = 'es-CL';
    }

    // if locale is 'en' change to 'en-US'
    if (locale === 'en') {
        locale = 'en-US';
    }

    // Create a new Intl.NumberFormat object with the given locale and options
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });

    // Format the number using the formatter
    return formatter.format(number);
}


export { formatNumber };