<template>
  <div>
    <h2>
      Dashboard General - {{portafolio.nombre}} [{{plantas.length}} Plantas]
      <span v-if="mes_dashboard" style="text-transform:capitalize">
        - {{mes_dashboard.format('MMMM YYYY')}}
      </span>
    </h2>

    <!-- Seleccion de Mes -->
    <div class="row card-mes py-4">
      <div class="col-12 col-md-6">
        <card>
          <div class="row">
            <div class="col-md-12">
              <p>Escoger mes</p>
              <date-picker 
                class="datepicker"
                :clearable=false
                v-model="fecha_dashboard"
                type="month"
                valueType="format"
                @input="cambiarMesDashboard"
              >
              </date-picker>
            </div>
          </div>
          <!-- Botones -->
          <div class="row mt-2">
            <div class="col-md-6">
              <button class="btn btn-success" @click="pedirExcelHistorico">Generar Excel Histórico</button>
            </div>
            <div class="col-md-6">
              <button class="btn btn-success" @click="pedirCalculoDeDashboard">Calcular Datos</button>
            </div>
            <div class="col-md-6">
              <button class="btn btn-success" @click="pedirBitacoras">Descargar Bitácoras del mes</button>
              <i v-if="loading.descargaBitacoras" class="fas fa-spinner fa-spin"></i>
            </div>
            <div class="col-md-6">
              <router-link
                :to="{ name: 'asignador', query: {portafolio: portafolio.codigo}}"
              >
              <button class="btn btn-success">Asignar Datos Generales</button>
              </router-link>
            </div>
          </div>
          <hr>
          <!-- Descarga de datos -->
          <div class="row">
            <div class="col-12">
              <label class="control-label">
                Seleccionar Fechas
              </label>
              <br>
              <date-picker 
                class="datepicker"
                v-model="fechaDescargaGeneracion"
                valueType="format"
                range
              >
              </date-picker>
            </div>
            <div class="col-12 mt-2">
              <button class="btn btn-success" @click="pedirGeneracionPortafolio">
                Descargar .zip Generación por planta
                <i v-if="loading.descargaGeneracion" class="fas fa-spinner fa-spin"></i>
              </button>
            </div>
            <div class="col-12 mt-2">
              <button class="btn btn-success" @click="pedirRadiacionPortafolio">
                Descargar .zip Radiación por planta
                <i v-if="loading.descargaRadiacion" class="fas fa-spinner fa-spin"></i>
              </button>
            </div>
          </div>
        </card>
        <card class="recuadro-errores">
          <div class="row">
            <div class="col-md-12">
              <h6>Resumen</h6>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h6>Plantas con PR O&M &lt; al estimado</h6>

                  <ul>
                    <li
                      v-for="planta in plantasPrMenorEstimado"
                      :key="`pr_menor_${planta.id}`"
                      @click="scrollToRef(`tr_${planta.id}`)"
                      class="item-resumen"
                    >
                      {{ planta.nombre }}: {{ planta.datos_dashboard.pr_om_acum  | numeral('0,0.0') }}%
                    </li>
                  </ul>
                  
                </div>
                <div class="col-12 col-md-6">
                  <h6>Plantas con Disp. Real &lt; 97%</h6>
                  
                  <ul>
                    <li
                      v-for="planta in plantasDispMenor95"
                      :key="`disp_menor_${planta.id}`"
                      @click="scrollToRef(`tr_${planta.id}`)"
                      class="item-resumen"
                    >
                      {{ planta.nombre }}: {{ planta.datos_dashboard.disp_real_acum  | numeral('0,0.0') }}%
                    </li>
                  </ul>
                  
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- Mapa -->
      <div class="col-12 col-md-6">
        <card>
          <div class="row">
            <div class="col-12">
              <DeltaMap
                v-if="portafolio.codigo_alarmas"
                :portafolio="portafolio"
              />
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- Graficos -->
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row">
              <div class="col-sm-12 col-md-3 text-left">
                <h2 class="card-title">{{graficoActivo.toUpperCase()}}</h2>
              </div>
              <div class="col-sm-12 col-md-3 text-center">
                <input type="checkbox" v-model="sorted" @change="generarGraficos()" name="sorted">
                <label class="ml-2" for="sorted">Mayor a menor simulación?</label>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <div
                      class="btn-group btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <label v-for="option in tipoGraficos"
                        :key="option"
                        class="btn btn-sm btn-primary btn-simple"
                        :class="{active: graficoActivo === option}"
                        :id="option"
                      >
                        <input type="radio"
                          @click="cambiarTipoGrafico(option)"
                          name="options" autocomplete="off"
                          :checked="graficoActivo === option"
                        >
                        {{option}}
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="btn-group btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <label v-for="option in subtipoGraficos[graficoActivo]"
                        :key="option"
                        class="btn btn-sm btn-primary btn-simple"
                        :class="{active: subGraficoActivo === option}"
                        :id="option"
                      >
                        <input type="radio"
                          @click="cambiarTipoGrafico(graficoActivo, option)"
                          name="options" autocomplete="off"
                          :checked="subGraficoActivo === option"
                        >
                        {{option}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-show="graficoActivo=='disp.'" class="chart-area-wrapper">
            <div v-if="graficoDispData" class="chart-area-temp" :style="{width: widthGraficoWrapper}">
              <bar-chart 
                :style="grafico_style"
                chart-id="grafico-dashboard-disp"
                ref="grafico-dashboard-disp"
                :chart-data="graficoDispData"
                :extra-options="graficoDispRealOptions"
              >
              </bar-chart >
            </div>
          </div>
          <div v-show="graficoActivo=='pr'" class="chart-area-wrapper">
            <div v-if="graficoPRData" class="chart-area-temp" :style="{width: widthGraficoWrapper}">
              <bar-chart 
                :style="grafico_style"
                chart-id="grafico-dashboard-pr"
                ref="grafico-dashboard-pr"
                :chart-data="graficoPRData"
                :extra-options="graficoPROptions"
              >
              </bar-chart >
            </div>
          </div>
          <div v-show="graficoActivo=='generación'">
            <div v-show="subGraficoActivo=='planta'" class="chart-area-wrapper">
              <div v-if="graficoGenData" class="chart-area-temp" :style="{width: widthGraficoWrapper}">
                <bar-chart 
                  :style="grafico_style"
                  chart-id="grafico-dashboard-generacion"
                  ref="grafico-dashboard-generacion"
                  :chart-data="graficoGenData"
                  :extra-options="graficoGenOptions"
                >
                </bar-chart >
              </div>
            </div>
            <div v-show="subGraficoActivo=='mensual'" class="chart-area-wrapper">
              <div v-if="graficoMensualData" class="chart-area-temp" :style="{width: widthGraficoWrapper}">
                <bar-chart 
                  :style="grafico_style"
                  chart-id="grafico-dashboard-mensual"
                  ref="grafico-dashboard-mensual"
                  :chart-data="graficoMensualData"
                  :extra-options="graficoMensualOptions"
                >
                </bar-chart >
              </div>
            </div>
          </div>
          <div v-show="graficoActivo=='irradiación'">
            <div v-show="subGraficoActivo=='planta'" class="chart-area-wrapper">
              <div v-if="graficoIrrData" class="chart-area-temp" :style="{width: widthGraficoWrapper}">
                <bar-chart 
                  :style="grafico_style"
                  chart-id="grafico-dashboard-irradiacion"
                  ref="grafico-dashboard-irradiacion"
                  :chart-data="graficoIrrData"
                  :extra-options="graficoIrrOptions"
                >
                </bar-chart >
              </div>
            </div>
            <div v-show="subGraficoActivo=='mensual'" class="chart-area-wrapper">
              <div v-if="graficoMensualIrrData" class="chart-area-temp" :style="{width: widthGraficoWrapper}">
                <bar-chart 
                  :style="grafico_style"
                  chart-id="grafico-dashboard-mensual-irr"
                  ref="grafico-dashboard-mensual-irr"
                  :chart-data="graficoMensualIrrData"
                  :extra-options="graficoMensualIrrOptions"
                >
                </bar-chart >
              </div>
            </div>
          </div>
          
          
        </card>
      </div>
    </div>

    <!-- Tabla Dashboard -->
    <div class="row">
      <div class="col-12">
        <card>
          <div class="table-responsive table-sticky">
            <table class="table tablesorter table-hover text-center table-dashboard">
              <thead class="sticky-header">
                <tr>
                  <th>Nombre</th>
                  <th></th>
                  <th class="td-acum">Acum.</th>
                  <th v-for="n in array_fechas" :key="'th_head_'+n">{{n.date()}}</th>
                </tr>
              </thead>
              <tbody>
                 <template v-for="item in plantas">
                  <tr class="dashboard-first-line" :ref="`tr_${item.id}`" :key="'tr_'+item.id">
                    <td style="word-break: break-word;width: 13%;" rowspan="6">
                      <router-link
                        :to="{ name: 'planta', params: { id: item.id }}"
                        style="font-size: 13px"
                      >
                        {{item.nombre}}
                      </router-link>
                      <p v-if="item.datos_dashboard.error" class="info-errores">{{item.datos_dashboard.error}}</p>
                      <p v-else class="info-fecha">Calculado el: {{item.ultima_fecha.format('DD-MM-YYYY HH:mm')}}</p>

                      <p v-if="pvsols[item.id]" class="info-fecha">PR PVSol: {{pvsols[item.id]['pr'] | numeral('0,0.00')}}%</p>
                      <p v-else class="info-errores">PR PVSol: Cargar PVSol</p>

                      <p v-if="pvsols[item.id]" class="info-fecha">
                        Coef. PR G.:
                        {{pvsols[item.id]['coef_pr_garantizado'] | numeral('0,0.00')}}%
                      </p>

                      <p class="info-fecha">Gen. Web:
                        <i v-if="loading.gen_mensual" class="fas fa-spinner fa-spin"></i>
                        <span v-else>
                          {{generacion_mensual[item.id].valor | numeral('0,0.0')}}
                        </span>
                      </p>

                      <span :disabled="loading.bitacoras" class="btn btn-bitacora btn-primary" @click="abrirBitacora(item.id, item.nombre)">
                        Bitácora Mensual
                        <i v-if="loading.bitacoras" class="fas fa-spinner fa-spin"></i>
                        <span class="n-entradas" v-else>({{bitacoras[item.id].length}})</span>
                      </span>

                      <span :disabled="loading.anotaciones" class="btn btn-bitacora btn-success mt-2"  @click="abrirAnotaciones(item.id, item.nombre)">
                        Anotaciones
                        <i v-if="loading.anotaciones" class="fas fa-spinner fa-spin"></i>
                        <span class="n-entradas" v-else>({{anotaciones[item.id].length}})</span>
                      </span>

                      <span class="btn btn-bitacora btn-warning mt-2"  @click="pedirCalculoDeDashboardPlanta(item.id)">
                        Recalcular datos
                      </span>
                      
                    </td>
                    <td>
                      <a target="blank"
                        :href="getUrlExerna(item)">
                        Irr. Real
                      </a>
                    </td>
                    <td class="td-acum"><b>{{item.datos_dashboard.irr_acum | numeral('0,0.0')}}</b></td>
                    <td v-for="n in array_fechas" :key="'td_irr_'+n">
                      <template v-if="despuesDeHoy(n)">--</template>
                      <template v-else>{{item.datos_dashboard.irr_real[n.date()] | numeral('0,0.0')}}</template>
                    </td>
                  </tr>
                  <tr :key="'tr_gen_'+item.id">
                    <td>Gen</td>
                    <td class="td-acum"><b>{{item.datos_dashboard.gen_acum | numeral('0,0.0')}}</b></td>
                    <td
                      v-for="n in array_fechas"
                      :class="diaBitacoraClase(item.id, n)"
                      v-tooltip="{content: comentarioTooltip(item.id, n), trigger:'hover'}" :key="'td_gen_'+n"
                    >
                      <template v-if="despuesDeHoy(n)">--</template>
                      <template v-else>{{item.datos_dashboard.gen_real[n.date()] | numeral('0,0.0')}}</template>
                    </td>
                  </tr>
                  <tr :key="'tr_pr_'+item.id">
                    <td>PR Real</td>
                    <td class="td-acum" :style="estiloPR(item.datos_dashboard.pr_real_acum, pvsols[item.id])"><b>{{item.datos_dashboard.pr_real_acum | numeral('0,0.0')}}</b></td>
                    <td v-for="n in array_fechas" :key="'td_pr_'+n" :style="estiloPR(item.datos_dashboard.pr_real[n.date()], pvsols[item.id])">
                      <template v-if="despuesDeHoy(n)">--</template>
                      <template v-else>{{item.datos_dashboard.pr_real[n.date()] | numeral('0,0.0')}}</template>
                    </td>
                  </tr>
                  <tr :key="'tr_pr_om_'+item.id">
                    <td>PR O&M</td>
                    <td class="td-acum" :style="estiloPR(item.datos_dashboard.pr_om_acum, pvsols[item.id])"><b>{{item.datos_dashboard.pr_om_acum | numeral('0,0.0')}}</b></td>
                    <td v-for="n in array_fechas" :key="'td_pr_om_'+n" :style="estiloPR(item.datos_dashboard.pr_om[n.date()], pvsols[item.id])">
                      <template v-if="despuesDeHoy(n)">--</template>
                      <template v-else>{{item.datos_dashboard.pr_om[n.date()] | numeral('0,0.0')}}</template>
                    </td>
                  </tr>
                  <tr :key="'tr_disp_'+item.id">
                    <td>Disp. Real</td>
                    <td class="td-acum" :style="estiloDisponbilidad(item.datos_dashboard.disp_real_acum)"><b>{{item.datos_dashboard.disp_real_acum | numeral('0,0.0')}}</b></td>
                    <td v-for="n in array_fechas" :key="'td_disp_'+n" :style="estiloDisponbilidad(item.datos_dashboard.disp_real[n.date()])">
                      <template v-if="despuesDeHoy(n)">--</template>
                      <template v-else>{{item.datos_dashboard.disp_real[n.date()] | numeral('0,0.0')}}</template>
                    </td>
                  </tr>
                  <tr :key="'tr_disp_om_'+item.id">
                    <td>Disp. O&M</td>
                    <td class="td-acum" :style="estiloDisponbilidad(item.datos_dashboard.disp_om_acum)"><b>{{item.datos_dashboard.disp_om_acum | numeral('0,0.0')}}</b></td>
                    <td v-for="n in array_fechas" :key="'td_disp_om_'+n" :style="estiloDisponbilidad(item.datos_dashboard.disp_om[n.date()])">
                      <template v-if="despuesDeHoy(n)">--</template>
                      <template v-else>{{item.datos_dashboard.disp_om[n.date()] | numeral('0,0.0')}}</template>
                    </td>
                  </tr>
                 </template>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card v-if="showHeatmapBox">
          <div class="text-center">
            <h2>Heatmap: Seleccionar planta</h2>
            <div class="listado-nombres-plantas mb-2">
              <select
                v-model="planta_seleccionada_heatmap"
                @change="plantaSeleccionadaHeatmap(planta_seleccionada_heatmap)"
              >
                <option v-for="planta in plantas" :key="planta.id" :value="planta.id">
                  {{ planta.nombre }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="loading.nombres_inversores">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          <div class="text-center" v-if="!loading.nombres_inversores && planta_seleccionada_heatmap">
            <h2>Escoger inversores a mostrar</h2>
            <div class="listado-nombres-inversores mb-2">
              <div
                v-for="nombre_inversor in heatmap_listado_nombres"
                :key="nombre_inversor"
              >
                {{ nombre_inversor }}
                <input
                  type="checkbox"
                  v-model="heatmap_nombres_filtrados"
                  :value="nombre_inversor"
                  :name="nombre_inversor"
                >
              </div>
            </div>
            <button
              class="btn btn-success"
              @click="peticionManualDatosHeatmap"
            >
              Recargar Heatmap
            </button>
          </div>
        </card>
      </div>
      <div class="col-12">
        <card v-if="showHeatmap">
          <div v-if="loading.heatmap">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          <div class="heat-general" v-else>
            <div class="heat-row">
              <h1>Rendimiento específico [kWh/kWp]</h1>
            </div>
            <div class="heat-content">
              <div class="heat-eje">
                <span>
                  Día del Mes
                </span>
              </div>
              <div class="heat-data">
                <div 
                  v-for="(fila, idx) in heatmap_data.data.index" :key="'fila_'+idx"
                  class="heat-row"
                >
                  <div class="cuadro-fila mr-2">
                    {{ fila }}
                  </div>
                  <div
                    v-for="(col, idx_col) in heatmap_data.data.data[idx]" :key="'col_'+idx_col" 
                    class="cuadro-heat"
                    :style="{'background': generarColorCuadro(heatmap_data.porcentajes.data[idx][idx_col])}"
                  >
                    {{ col }}
                  </div>
                </div>
                <div class="heat-row">
                  <div class="cuadro-fila mr-2 mt-4" />
                  <div
                    v-for="(col, idx_foot) in heatmap_data.data.columns" :key="'foot_'+idx_foot" 
                    class="cuadro-heat"
                  >
                    {{ col }}
                  </div>
                </div>
              </div>
            </div>
            <div class="heat-row">
              <h2>Inversor</h2>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- Modals -->
    <!-- Modal limpieza -->
    <modal 
      :show="modalLimpieza.show"
      body-classes="p-0"
      modal-classes="modal-lg"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div v-if="modalLimpieza.modo == 'crear'">
            <h2>Agendar día</h2>
            <p>Se agregará una limpieza el día {{modalLimpieza.dia}}</p>
          </div>

          <div v-if="modalLimpieza.modo == 'eliminar'">
            <h2>Limpieza agendada</h2>
            <p>Día asignado: {{modalLimpieza.dia}}</p>
            <p>Creado por: {{handleUser(modalLimpieza.created_by)}}</p>
            <p>Última vez editado por: {{handleUser(modalLimpieza.last_updated_by)}}</p>
          </div>
          
          <br>
          <p>Ingresar Comentario:</p>

          <textarea
            rows="4"
            cols="80"
            class="form-control"
            placeholder="Comentario"
            v-model="modalLimpieza.comentario"
          >
          </textarea>
        </template>
      </card>
      <template slot="footer">
        <base-button type="primary" v-if="modalLimpieza.modo == 'crear'" @click="guardarDiaLimpieza">
          Aceptar
        </base-button>
        <base-button type="primary" class="mr-4" v-if="modalLimpieza.modo == 'eliminar'" @click="editarDiaLimpieza">
          Editar
        </base-button>
        <base-button type="warning" v-if="modalLimpieza.modo == 'eliminar'" @click="eliminarDiaLimpieza">
          Eliminar
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="modalLimpieza.show = false">
          Cancelar
        </base-button>
      </template>
    </modal>

    <!-- Modal bitacora-->
    <modal 
      :show="modalBitacora.show"
      body-classes="p-0"
      modal-classes="modal-lg"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h2>Bitácora de {{modalBitacora.nombre}}</h2>

          <div class="bitacora-simbologia">
            <div>
              <div class="cuadro bitacora-corte"></div>
              <span>Corte de Pasto</span>
            </div>
            <div>
              <div class="cuadro  bitacora-desviacion"></div>
              <span>Desviación/Anomalía</span>
            </div>
            <div>
              <div class="cuadro bitacora-limpieza"></div>
              <span>Limpieza</span>
            </div>
          </div>

          <div class="bitacora-listado">
            <div v-for="item in modalBitacora.listado" :key="'item_'+item.id" 
              class="bitacora-item"
              :class="claseBitacoraItem(item.tipo)"
            >
              <div class="bitacora-item-contenido">
                <div class="bitacora-item-fecha">
                  {{handleFechaBitacora(item.fecha)}} - {{handleFechaBitacora(item.fecha_fin)}}
                </div>
                <div class="bitacora-item-texto">
                  <span v-if="item.aplica_ot">OT N° {{item.n_ot}} - </span>
                  <span v-if="item.evento">{{ opciones_eventos[item.evento] }} - </span>
                  
                  <span v-if="item.falla"> Falla - </span>
                  <span v-else> No Falla - </span>

                  {{ item.horas_desconexion }} Hrs. desc - 
                  {{ item.energia_perdida }} kWh perdidos - 

                  {{item.descripcion}}
                </div>
              </div>
              <div class="bitacora-item-contenido-right">
                <div class="bitacora-item-usuarios">
                  <span>{{handleUserBitacora(item.created_by, 'crear')}}</span>
                  <span>{{handleUserBitacora(item.last_updated_by, 'editar')}}</span>
                </div>
                <div class="bitacora-item-botones">
                  <div class="btn" @click="abrirEditarBitacora(item)">
                    <i class="fas fa-edit"></i>
                  </div>
                  <div class="btn" @click="abrirEliminarBitacora(item)">
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <hr>
          <p>Ingresar evento:</p>

          <form role="form">
            <div class="row">
              <div class="col-md-3">
                <base-input label="Tipo OT">
                  <select class="form-control"
                    v-model="bitacoraNuevo.tipo"
                    @change="cambiarAplicaOt('agregar')"
                  >
                    <option value="LIMPIEZA">Limpieza</option>
                    <option value="CORTE">Corte de Pasto</option>
                    <option value="DESVIACION">Desviación/Anomalía</option>
                  </select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input label="Evento">
                  <select class="form-control"
                    v-model="bitacoraNuevo.evento"
                  >
                    <option value='ACCIONAMIENTO_RI'>Accionamiento RI</option>
                    <option value='FALLA_CC'>Falla CC</option>
                    <option value='FALLA_TABLERO'>Falla Tablero</option>
                    <option value='DESCONEXION_JUSTIFICADA'>Desconexión Justificada</option>
                    <option value='FALLA_INVERSOR'>Falla Inversor</option>
                    <option value='DISMINUCION_DE_POTENCIA'>Disminución de Potencia</option>
                    <option value='LIMITACION_AUTOCONSUMO'>Limitación Autoconsumo</option>
                    <option value='ROBO'>Robo</option>
                    <option value='FALLA_RI'>Falla RI</option>
                    <option value='DESCONOCIDO'>Desconocido</option>
                  </select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input label="Falla">
                  <select class="form-control"
                    v-model="bitacoraNuevo.falla"
                  >
                    <option value='true'>Sí</option>
                    <option value='false'>No</option>
                  </select>
                </base-input>
              </div>
              <div class="col-md-3 datepicker-col">
                <label class="control-label">
                  Fecha Inicio - Fin
                </label>
                <date-picker 
                  class="datepicker"
                  range
                  :clearable=false
                  v-model="bitacoraNuevo.fecha"
                  valueType="format"
                >
                </date-picker>
              </div>
              <div class="col-md-3">
                <label class="control-label">
                  Aplica OT
                </label>
                <select 
                  class="form-control"
                  v-model="bitacoraNuevo.aplica_ot"
                  :disabled="bitacoraNuevo.tipo=='LIMPIEZA' || bitacoraNuevo.tipo=='CORTE'"
                >
                  <option :value='true'>Aplica</option>
                  <option :value='false'>No Aplica</option>
                </select>
              </div>
              <div class="col-md-3">
                <base-input 
                  :disabled="!bitacoraNuevo.aplica_ot"
                  label="N° de OT"
                  placeholder="N° de OT"
                  v-model="bitacoraNuevo.n_ot"
                  type="number"
                >
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input 
                  label="Horas de Desconexión"
                  placeholder="Horas de Desconexión"
                  v-model="bitacoraNuevo.horas_desconexion"
                  type="number"
                >
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input 
                  label="Energía Perdida [kWh]"
                  placeholder="Energía Perdida [kWh]"
                  v-model="bitacoraNuevo.energia_perdida"
                  type="number"
                >
                </base-input>
              </div>
            </div>
            <label>
              Comentario
              <span class="text-danger" v-if="bitacoraNuevo.tipo==='DESVIACION'">*</span>
            </label>
            <textarea
              rows="4"
              cols="80"
              class="form-control"
              placeholder="Comentario"
              v-model="bitacoraNuevo.descripcion"
            >
            </textarea>
          </form>
        </template>
      </card>
      <template slot="footer">
        <base-button type="primary" @click="guardarBitacoraNueva(modalBitacora.planta)">
          Agregar
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="modalBitacora.show = false">
          Cancelar
        </base-button>
      </template>
    </modal>

    <!-- Modal para editar bitacora -->
    <modal 
      :show="modalBitacoraEditar.show"
      body-classes="p-0"
      modal-classes="modal-lg"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h2>Editar Evento</h2>
          <form role="form">
            <div class="row">
              <div class="col-md-3">
                <base-input label="Tipo de OT">
                  <select 
                    class="form-control"
                    v-model="modalBitacoraEditar.item.tipo"
                    @change="cambiarAplicaOt('editar')"
                  >
                    <option value="LIMPIEZA">Limpieza</option>
                    <option value="CORTE">Corte de Pasto</option>
                    <option value="DESVIACION">Desviación/Anomalía</option>
                  </select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input label="Evento">
                  <select class="form-control"
                    v-model="modalBitacoraEditar.item.evento"
                  >
                    <option value='ACCIONAMIENTO_RI'>Accionamiento RI</option>
                    <option value='FALLA_CC'>Falla CC</option>
                    <option value='FALLA_TABLERO'>Falla Tablero</option>
                    <option value='DESCONEXION_JUSTIFICADA'>Desconexión Justificada</option>
                    <option value='FALLA_INVERSOR'>Falla Inversor</option>
                    <option value='DISMINUCION_DE_POTENCIA'>Disminución de Potencia</option>
                    <option value='LIMITACION_AUTOCONSUMO'>Limitación Autoconsumo</option>
                    <option value='ROBO'>Robo</option>
                    <option value='FALLA_RI'>Falla RI</option>
                    <option value='DESCONOCIDO'>Desconocido</option>
                  </select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input label="Falla">
                  <select class="form-control"
                    v-model="modalBitacoraEditar.item.falla"
                  >
                    <option value='true'>Sí</option>
                    <option value='false'>No</option>
                  </select>
                </base-input>
              </div>
              <div class="col-md-3 datepicker-col">
                <label class="control-label">
                  Fecha Inicio - Fin
                </label>
                <date-picker 
                  class="datepicker"
                  range
                  :clearable=false
                  v-model="modalBitacoraEditar.item.fecha"
                  valueType="format"
                >
                </date-picker>
              </div>
              <div class="col-md-3">
                <label class="control-label">
                  Aplica OT
                </label>
                <select
                  class="form-control"
                  v-model="modalBitacoraEditar.item.aplica_ot"
                  :disabled="modalBitacoraEditar.item.tipo=='LIMPIEZA' || modalBitacoraEditar.item.tipo=='CORTE'"
                >
                  <option :value='true'>Aplica</option>
                  <option :value='false'>No Aplica</option>
                </select>
              </div>
              <div class="col-md-3">
                <base-input 
                  :disabled="!modalBitacoraEditar.item.aplica_ot"
                  label="N° de OT"
                  placeholder="N° de OT"
                  v-model="modalBitacoraEditar.item.n_ot"
                  type="number"
                >
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input 
                  label="Horas de Desconexión"
                  placeholder="Horas de Desconexión"
                  v-model="modalBitacoraEditar.item.horas_desconexion"
                  type="number"
                >
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input 
                  label="Energía Perdida [kWh]"
                  placeholder="Energía Perdida [kWh]"
                  v-model="modalBitacoraEditar.item.energia_perdida"
                  type="number"
                >
                </base-input>
              </div>
            </div>
            <label>
              Comentario
              <span class="text-danger" v-if="modalBitacoraEditar.item.tipo==='DESVIACION'">*</span>
            </label>
            <textarea
              rows="4"
              cols="80"
              class="form-control"
              placeholder="Comentario"
              v-model="modalBitacoraEditar.item.descripcion"
            >
            </textarea>
          </form>
        </template>
      </card>
      <template slot="footer">
        <base-button type="primary" @click="editarBitacora">
          Guardar
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="cancelarEditarBitacora">
          Cancelar
        </base-button>
      </template>
    </modal>

    <!-- Modal para confirmar eliminar bitacora -->
    <modal 
      :show="modalBitacoraEliminar.show"
      body-classes="p-0"
      modal-classes="modal-lg"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h2>Confirmar</h2>

          <p>¿Desea eliminar esta entrada?</p>
        </template>
      </card>
      <template slot="footer">
        <base-button type="danger" @click="confirmarEliminarBitacora">
          Eliminar
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="cancelarEliminarBitacora">
          Cancelar
        </base-button>
      </template>
    </modal>

    <!-- Modal de anotaciones -->
    <modal 
      :show="modalAnotaciones.show"
      body-classes="p-0"
      modal-classes="modal-lg"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h2>Anotaciones de {{modalAnotaciones.nombre}}</h2>

          <div class="anotacion-listado">
            <div v-for="item in modalAnotaciones.listado" :key="'item_'+item.id" class="anotacion-item">
              <div class="anotacion-item-contenido">
                <div class="anotacion-item-fecha">
                  {{handleFechaAnotacion(item.created_at)}}
                </div>
                <div class="anotacion-item-texto">
                  {{item.descripcion}}
                </div>
              </div>
              <div class="anotacion-item-contenido-right">
                <div class="anotacion-item-usuarios">
                  <span>{{handleUserBitacora(item.created_by, 'crear')}}</span>
                  <span>{{handleUserBitacora(item.last_updated_by, 'editar')}}</span>
                </div>
                <div class="anotacion-item-botones">
                  <div class="btn" @click="abrirEditarAnotacion(item)">
                    <i class="fas fa-edit"></i>
                  </div>
                  <div class="btn" @click="abrirResolverAnotacion(item)">
                    <i class="fas fa-check"></i>
                  </div>
                  <div class="btn" @click="abrirEliminarAnotacion(item)">
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <hr>
          <p>Ingresar anotación:</p>

          <textarea
            rows="4"
            cols="80"
            class="form-control"
            placeholder="Descripción"
            v-model="anotacionNueva.descripcion"
          >
          </textarea>
        </template>
      </card>
      <template slot="footer">
        <base-button type="primary" @click="guardarAnotacionNueva(modalAnotaciones.planta)">
          Agregar
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="modalAnotaciones.show = false">
          Cancelar
        </base-button>
      </template>
    </modal>

    <!-- Modal para editar anotacion -->
    <modal 
      :show="modalAnotacionEditar.show"
      body-classes="p-0"
      modal-classes="modal-lg"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h2>Editar Anotación</h2>

          <textarea
            rows="4"
            cols="80"
            class="form-control"
            placeholder="Descripción"
            v-model="modalAnotacionEditar.item.descripcion"
          >
          </textarea>
        </template>
      </card>
      <template slot="footer">
        <base-button type="primary" @click="editarAnotacion">
          Guardar
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="cancelarEditarAnotacion">
          Cancelar
        </base-button>
      </template>
    </modal>

    <!-- Modal para confirmar eliminar anotación -->
    <modal 
      :show="modalAnotacionEliminar.show"
      body-classes="p-0"
      modal-classes="modal-lg"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h2>Confirmar</h2>

          <p>¿Desea eliminar esta anotación?</p>
        </template>
      </card>
      <template slot="footer">
        <base-button type="danger" @click="confirmarEliminarAnotacion">
          Eliminar
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="cancelarEliminarAnotacion">
          Cancelar
        </base-button>
      </template>
    </modal>

    <!-- Modal para confirmar resolver anotación -->
    <modal 
      :show="modalAnotacionResolver.show"
      body-classes="p-0"
      modal-classes="modal-lg"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h2>Resolver Anotación</h2>

          <p>Esta anotación quedará marcada como resuelta</p>
        </template>
      </card>
      <template slot="footer">
        <base-button type="warning" @click="confirmarResolverAnotacion">
          Resolver
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="cancelarResolverAnotacion">
          Cancelar
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { BaseTable } from "@/components";
import DeltaMap from "@/components/DeltaMap";
import DatePicker from 'vue2-datepicker';
import Modal from '@/components/Modal';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
// dayjs.extend(localeData);
require('dayjs/locale/es');
dayjs.locale('es');

import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, {locale: 'es'});

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);


import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
// import {dashboardPRChartOptions, dashboardDispChartOptions, dashboardGenChartOptions, dashboardIrrChartOptions} from '@/components/Charts/config';

export default {
  name: 'starter-page',
  components: {
    BaseTable,
    DatePicker,
    Modal,
    BarChart,
    DeltaMap
  },
  data() {
    return {
      heatmap_data: {},
      heatmap_nombres_filtrados: [],
      heatmap_listado_nombres: [],
      options: {
        chart: {
          type: 'heatmap',
          height: 'auto',
          // widht: '750px',
        },
        dataLabels: {
          enabled: true
        },
        plotOptions: {
          heatmap: {
            distributed: true,
            enableShades: true,
            shadeIntensity: 0.5,
          }
        },
        colors: ["#357b38"],
        title: {
          text: 'HeatMap Plantas'
        },
      },
      series: [],
      // Variables heatmap
      showHeatmap: false,
      // Flags de carga
      loading: {
        bitacoras: true,
        anotaciones: true,
        gen_mensual: true,
        descargaGeneracion: false,
        descargaRadiacion: false,
        descargaBitacoras: false,
        heatmap: false,
        nombres_inversores: false,
      },
      // Objeto dayjs con le fecha actual
      hoy: null,
      array_fechas: [],
      plantas: [],
      filas_tabla: [
        'irr_real', 'gen_real', 'pr_real', 'pr_om', 'disp_real', 'disp_om'
      ],
      // cantidad de dias en el mes visualizado
      dias_mes: 0,
      // Objeto dayjs del inicio del mes visualizado
      mes_dashboard: null,
      portafolio: {},
      fecha_dashboard: null,
      pvsols: {},
      dias_limpieza: {},
      modalLimpieza: {
        id: null,
        planta: null,
        show: false,
        dia: 0,
        modo: 'crear',
        comentario: '',
        created_by: '',
        last_updated_by: '',
      },
      modalBitacora: {
        show: false,
        planta: null,
        listado: [],
        nombre: '',
      },
      modalBitacoraEditar: {
        show: false,
        item: {
          id: null,
          descripcion: '',
          fecha: '',
          n_ot: null,
          tipo: 'LIMPIEZA',
          evento: 'DESCONOCIDO',
          falla: 'false',
          horas_desconexion: 0,
          energia_perdida: 0,
        }
      },
      modalBitacoraEliminar: {
        show: false,
        item: null,
      },
      opciones_eventos: {
        ACCIONAMIENTO_RI: 'Accionamiento RI',
        FALLA_CC: 'Falla CC',
        FALLA_TABLERO: 'Falla Tablero',
        DESCONEXION_JUSTIFICADA: 'Desconexión Justificada',
        FALLA_INVERSOR: 'Falla Inversor',
        DISMINUCION_DE_POTENCIA: 'Disminución de Potencia',
        LIMITACION_AUTOCONSUMO: 'Limitación Autoconsumo',
        ROBO: 'Robo',
        FALLA_RI: 'Falla RI',
        DESCONOCIDO: 'Desconocido',
      },
      bitacoraNuevo: {
        descripcion: '',
        fecha: [null, null],
        aplica_ot: true,
        n_ot: null,
        tipo: 'LIMPIEZA',
        evento: 'DESCONOCIDO',
        falla: 'false',
        horas_desconexion: 0,
        energia_perdida: 0,
      },
      modalAnotaciones: {
        show: false,
        planta: null,
        listado: [],
        nombre: '',
      },
      anotacionNueva: {
        descripcion: '',
      },
      modalAnotacionEditar: {
        show: false,
        item: {
          id: null,
          descripcion: '',
          resuelto: false,
        }
      },
      modalAnotacionEliminar: {
        show: false,
        item: null,
      },
      modalAnotacionResolver: {
        show: false,
        item: null,
      },
      bitacoras: {},
      anotaciones: {},
      datos_mensuales: {},
      generacion_mensual: {},
      graficoMensualData: null,
      graficoMensualIrrData: null,
      graficoDispData: null,
      graficoPRData: null,
      graficoGenData: null,
      graficoIrrData: null,
      graficoDispOMOptions: chartConfigs.dashboardDispChartOptions,
      graficoDispRealOptions: chartConfigs.dashboardDispChartOptions,
      graficoPROptions: chartConfigs.dashboardPRChartOptions,
      graficoGenOptions: chartConfigs.dashboardGenChartOptions,
      graficoIrrOptions: chartConfigs.dashboardIrrChartOptions,
      graficoDashboardOptions: chartConfigs.dashboardPRChartOptions,
      graficoMensualOptions: chartConfigs.dashboardMensualChartOptions,
      graficoMensualIrrOptions: chartConfigs.dashboardMensualIrrChartOptions,
      grafico_style: "height:400px; width:100%;",
      tipoGraficos: ['disp.', 'pr', 'generación', 'irradiación'],
      subtipoGraficos: {
        'generación': ['planta', 'mensual'],
        'irradiación': ['planta', 'mensual']
      },
      graficoActivo: 'generación',
      subGraficoActivo: 'planta',
      sorted: false,
      mismoMes: true,
      fechaDescargaGeneracion: null,
      planta_seleccionada_heatmap: null,
      showHeatmapBox: false,
    };
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    scrollToRef(refName){
      const element = this.$refs[refName];
      if(element.length > 0){
        element[0].scrollIntoView({ behavior: 'smooth' });
      }
    },

    claseBitacoraItem(tipo){
      return 'bitacora-'+tipo.toLowerCase();
    },

    cambiarAplicaOt(tipo){
      if(tipo=='agregar' && (this.bitacoraNuevo.tipo=='LIMPIEZA' || this.bitacoraNuevo.tipo=='CORTE')){
        this.bitacoraNuevo.aplica_ot = true;
      }
      else if(tipo=='editar' && (this.modalBitacoraEditar.item.tipo=='LIMPIEZA' || this.modalBitacoraEditar.item.tipo=='CORTE')){
        this.modalBitacoraEditar.item.aplica_ot = true;
      }
    },

    getUrlExerna(planta){
      if(planta.tipo==='FRONIUS'){
        return 'https://www.solarweb.com/Chart/AnalysisChart?pvSystemId='+planta.PvSystemId;
      }
      else if(planta.tipo==='HUAWEI'){
        return 'https://cl.fusionsolar.huawei.com/securitys!tologin.action'
      }
      return ''
    },

    // Retorn true si n es el dia actual
    // o después 
    // n: objeto Dayjs()
    despuesDeHoy(n){
      if(this.mismoMes){
        return this.hoy.date()<=n.date();
      }

      return false;
    },

    handleUser(user){
      if(user.first_name==='' && user.last_name==='')
        return 'Desconocido';
      return user.first_name + ' ' + user.last_name;
    },

    handleUserBitacora(user, tipo){
      if(user.first_name==='' && user.last_name==='')
        return '';
      
      let nombre = user.first_name[0] + '. ' + user.last_name;
      if(tipo=='crear'){
        return 'Creado por: ' + nombre;
      }
      else{
        return 'Editado por: ' + nombre;
      }
    },

    handleFechaBitacora(fecha){
      return fecha.slice(5).replace('-', '/')
    },

    handleFechaAnotacion(fecha){
      return dayjs(fecha).format('MM/DD')
    },

    cambiarTipoGrafico(tipo, subtipo = null){
      
      // this.graficoDashboardOptions = this.opcionesGraficos[tipo]
      this.subGraficoActivo = subtipo || 'planta';

      this.graficoActivo = tipo;
      this.generarGraficos();
    },

    celdaClick(event, planta, dia){
      // event.target.classList.toggle('dia-limpieza')
      
      // Setear datos del modal
      this.modalLimpieza.planta = planta;

      // Buscar si el dia seleccionado tiene datos
      if(this.dias_limpieza[planta].some(e => e.dia === dia)){
        console.log('tiene datos');
        let dia_existente = this.dias_limpieza[planta].filter(x => x.dia == dia)[0];
        this.modalLimpieza.id = dia_existente.id;
        this.modalLimpieza.dia = dia;
        this.modalLimpieza.comentario = dia_existente.comentario;
        this.modalLimpieza.modo = 'eliminar';
        this.modalLimpieza.created_by = dia_existente.created_by;
        this.modalLimpieza.last_updated_by = dia_existente.last_updated_by;
      }
      else{
        console.log('agregar nuevo');
        this.modalLimpieza.dia = dia;
        this.modalLimpieza.comentario = '';
        this.modalLimpieza.modo = 'crear';
      }

      this.modalLimpieza.show = true; 
    },

    diaBitacoraClase(planta, dia){
      if(this.bitacoras[planta]){
        for(let item of this.bitacoras[planta]){
          if(dia.isSame(item.fecha, 'day')){ 
            if(dia.isSame(item.fecha_fin, 'day')){ 
              return 'dia-limpieza-start-end';
            }
            return 'dia-limpieza-start';
          }
          
          if(dia.isBefore(item.fecha_fin, 'day') && dia.isAfter(item.fecha, 'day')){
            return 'dia-limpieza-middle';
          }

          // Coincide con el dia de fin
          if(dia.isSame(item.fecha_fin, 'day')){ 
            return 'dia-limpieza-end';
          }
        }
      }
        
      return '';
    },

    comentarioTooltip(planta, dia){
      if(!this.bitacoras[planta]) return '';
      
      let dias = this.bitacoras[planta].filter(item => {
        // Coincide con el dia de inicio
        if(dia.isSame(item.fecha, 'day')){ return true;}
        
        if(dia.isBefore(item.fecha_fin, 'day') && dia.isAfter(item.fecha, 'day')){ return true;}

        // Coincide con el dia de fin
        if(dia.isSame(item.fecha_fin, 'day')){ return true;}

        return false;
      });

      if(dias.length==0){
        return '';
      }
      else{
        let texto = '';
        dias.forEach(evento => {
          let fecha_fin = this.handleFechaBitacora(evento.fecha_fin);
          let fecha = this.handleFechaBitacora(evento.fecha);
          let tipo = evento.tipo;

          let texto_nuevo = '';

          if(evento.aplica_ot){
            texto_nuevo = `<div class="tooltip-circle bitacora-${tipo.toLowerCase()}"></div><b>${fecha} - ${fecha_fin}</b>: OT N° ${evento.n_ot} - ${evento.descripcion}`;
          }
          else{
            texto_nuevo = `<div class="tooltip-circle bitacora-${tipo.toLowerCase()}"></div><b>${fecha} - ${fecha_fin}</b>: ${evento.descripcion}`;
          }

          if(texto===''){
            texto = texto_nuevo
          }
          else{
            texto = `${texto}<br>${texto_nuevo}`
          }
        })
        return texto;
      }
    },

    usuarioTooltip(evento){
      return 'Test';
      // if(!this.dias_limpieza[planta]) return '';
      
      // let dias = this.dias_limpieza[planta].filter(item => item.dia == dia);

      // if(dias.length==0){
      //   return '';
      // }
      // else{
      //   return dias[0].comentario;
      // }
    },

    async guardarDiaLimpieza(){
      // llamar a api
      try {
        let planta_id = this.modalLimpieza.planta;
        let elem = {
          dia: this.modalLimpieza.dia,
          comentario: this.modalLimpieza.comentario,
        };

        let fecha = this.mes_dashboard.date(+this.modalLimpieza.dia);

        let res = await this.$http({
          method: 'POST',
          url: '/api/limpieza',
          data: {
            planta: planta_id,
            fecha: fecha.format('YYYY-MM-DD'),
            comentario: this.modalLimpieza.comentario,
          }
        });

        elem.id = res.data.id;
        elem.created_by = res.data.created_by;
        elem.last_updated_by = res.data.last_updated_by;

        this.dias_limpieza[planta_id].push(elem)
        this.modalLimpieza.show = false;

        this.notifyVue('Día guardado', 'success');

      } catch (error) {
        this.notifyVue('Error al guardar', 'warning');
        console.log(error);
      }
    },

    async editarDiaLimpieza(){
      try {
        let id = this.modalLimpieza.id;
        let planta_id = this.modalLimpieza.planta;
        let comentario =  this.modalLimpieza.comentario;

        await this.$http({
          method: 'PUT',
          url: '/api/limpieza/'+id,
          data: {
            comentario: this.modalLimpieza.comentario,
          }
        });

        this.dias_limpieza[planta_id] = this.dias_limpieza[planta_id].map(item => {
          if(item.id==id){
            item.comentario = comentario;
          }
          return item;
        })


        this.modalLimpieza.show = false;
        this.notifyVue('Día editado', 'success');

      } catch (error) {
        this.notifyVue('Error al editar', 'warning');
        console.log(error);
      }
    },

    async eliminarDiaLimpieza(){
      try {
        let id = this.modalLimpieza.id;
        let planta_id = this.modalLimpieza.planta;

        await this.$http({
          method: 'DELETE',
          url: '/api/limpieza/'+id,
        });

        this.dias_limpieza[planta_id] = this.dias_limpieza[planta_id].filter(item => item.id != id)

        this.modalLimpieza.show = false;
        this.notifyVue('Día eliminado', 'success');

      } catch (error) {
        this.notifyVue('Error al eliminar', 'warning');
        console.log(error);
      }
    },

    async cambiarMesDashboard(fecha){
      let inicio_acc = dayjs(fecha+'-01');
      let mes_dashboard = inicio_acc.startOf('month');
      let inicio_mes = mes_dashboard.startOf('month');
      let fin_mes = mes_dashboard.endOf('month');

      let temp_date = inicio_mes;
      this.array_fechas = [];
      while(temp_date.isBefore(fin_mes)) {
        this.array_fechas.push(temp_date);
        temp_date = temp_date.add(1, 'day');
      }

      this.dias_mes = fin_mes.diff(inicio_mes, 'day')+1;
      this.mes_dashboard = mes_dashboard;

      this.mismoMes = this.hoy.isSame(this.mes_dashboard, 'month');

      try {
        this.notifyVue('Pidiendo datos del dashboard', 'info');
        this.loading.bitacoras = true;

        // Pedir datos PVSOL del mes actual
        let res_pvsol = await this.$http({
          method: 'GET',
          url: '/api/pvsol/mensual',
          params: {
            portafolio: this.portafolio.codigo,
            mes: inicio_acc.month()+1,
            ano: inicio_acc.year()
          }
        });

        this.pvsols = res_pvsol.data;

        let res = await this.$http({
          method: 'GET',
          url: '/api/dashboard',
          params: {
            portafolio: this.portafolio.codigo,
            inicio_acc: inicio_acc.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          }
        });

        res.data.forEach(item => {
          item.ultima_fecha = dayjs(item.ultima_fecha);
        })
        this.plantas = res.data;
        this.notifyVue('Datos recibidos', 'success');

        // Datos de limpieza
        let res_limpieza = await this.$http({
          method: 'GET',
          url: '/api/limpieza',
          params: {
            portafolio: this.portafolio.codigo,
            fecha: inicio_acc.format('YYYY-MM-DD'),
          }
        });

        let dias_limpieza = res_limpieza.data;
        Object.keys(dias_limpieza).map(function(planta, index) {
          dias_limpieza[planta].map(dias => {
            dias.dia = dayjs(dias.fecha).date();
          })
        });

        this.dias_limpieza = dias_limpieza;

        let res_mensual = await this.$http({
          method: 'GET',
          url: `/api/dashboard/portafolio/${this.portafolio.codigo}/mensual`,
          params: {
            ano: inicio_acc.year(),
            mes: inicio_acc.month() + 1
          }
        });

        this.datos_mensuales = res_mensual.data;

        this.generarGraficos();

        // pedir datos de bitacora
        this.pedirDatosBitacoras(inicio_acc, fin_mes);

        // pedir datos de anotaciones
        this.pedirDatosAnotaciones();

        // pedir generacion mensual
        this.pedirGeneracionMensual(inicio_acc, fin_mes);

        // pedir datos heatmap
        this.pedirDatosHeatmap(inicio_acc, fin_mes)
      } catch (error) {
        this.notifyVue('Error al pedir datos', 'warning');
        console.log(error);
      }
    },

    // async obtenerDatosRango(){
    //   try {
    //     this.notifyVue('Pidiendo datos del portafolio', 'info');
    //     let fecha_fin;

    //     if(this.mismoMes){
    //       fecha_fin = this.hoy.subtract(1, 'day').endOf('day');
    //     }
    //     else{
    //       fecha_fin = this.mes_dashboard.endOf('month');
    //     }
        
    //     // let res = await this.$http({
    //     //   method: 'GET',
    //     //   url: '/api/dashboard/calcular',
    //     //   params: {
    //     //     portafolio: this.portafolio.codigo,
    //     //     inicio_acc: this.mes_dashboard.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    //     //     fin_acc: fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    //     //   }
    //     // });

    //   } catch (error) {
    //     this.notifyVue('Error al calcular datos', 'danger');
    //     console.log(error);
    //   }
    // },

    async pedirCalculoDeDashboard(){
      try {
        this.notifyVue('Calculando datos del dashboard', 'info');
        let fecha_fin;

        if(this.mismoMes){
          fecha_fin = this.hoy.subtract(1, 'day').endOf('day');
        }
        else{
          fecha_fin = this.mes_dashboard.endOf('month');
        }
        
        let res = await this.$http({
          method: 'GET',
          url: '/api/dashboard/calcular',
          params: {
            portafolio: this.portafolio.codigo,
            inicio_acc: this.mes_dashboard.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            fin_acc: fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          }
        });

        res.data.forEach(item => {
          item.ultima_fecha = dayjs(item.ultima_fecha);
        })
        this.plantas = res.data;
        this.notifyVue('Datos calculados', 'success');
        this.generarGraficos();

      } catch (error) {
        this.notifyVue('Error al calcular datos', 'danger');
        console.log(error);
      }
    },

    async pedirCalculoDeDashboardPlanta(planta_id){
      try {
        this.notifyVue('Calculando datos individuales', 'info');
        let fecha_fin;

        if(this.mismoMes){
          fecha_fin = this.hoy.subtract(1, 'day').endOf('day');
        }
        else{
          fecha_fin = this.mes_dashboard.endOf('month');
        }
        
        let res = await this.$http({
          method: 'GET',
          url: '/api/plantas/calcular',
          params: {
            planta: planta_id,
            inicio_acc: this.mes_dashboard.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            fin_acc: fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          }
        });

        let nuevos_datos = res.data[0]

        nuevos_datos.ultima_fecha = dayjs(nuevos_datos.ultima_fecha);

        this.plantas = this.plantas.map(planta => {
          if(planta.id === nuevos_datos.id){
            return nuevos_datos
          }
          return planta;
        })
        // this.plantas = res.data;
        this.notifyVue('Datos calculados', 'success');
        this.generarGraficos();

      } catch (error) {
        this.notifyVue('Error al calcular datos', 'danger');
        console.log(error);
      }
    },

    async pedirExcelHistorico(){
      try {
        // this.notifyVue('Calculando datos del dashboard', 'info');
        await this.$http({
          method: 'GET',
          url: '/api/excel/generar',
          params: {
            // portafolio: this.portafolio.codigo,
            inicio_acc: this.mes_dashboard.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            fin_acc: this.mes_dashboard.endOf('month').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          }
        });

        this.notifyVue('Generando archivo. Los datos serán enviados a su correo.', 'success');

      } catch (error) {
        this.notifyVue('Error al generar archivo', 'danger');
        console.log(error);
      }
    },

    async pedirGeneracionPortafolio(){
      let datos = this.fechaDescargaGeneracion;
      if(datos === null || datos[0]===null || datos[1]===null){
        this.notifyVue('Fechas no pueden quedar vacías', 'danger');
        return;
      }

      let portafolio = this.portafolio;
      let fecha_inicio = dayjs(datos[0]).startOf('day');
      let fecha_fin = dayjs(datos[1]).endOf('day');
      try {
        this.notifyVue('Generando archivo comprimido', 'info');
        this.loading.descargaGeneracion = true;
        let res = await this.$http({
          method: 'POST',
          url: '/api/generacion/portafolio/descarga',
          data: {
            fecha_inicio: fecha_inicio.unix(),
            fecha_fin: fecha_fin.unix(),
            portafolio: portafolio.codigo
          },
          responseType: 'blob',
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        let filename = `Generacion ${this.portafolio.nombre} - ${fecha_inicio.format('YYYY-MM-DD')} - ${fecha_fin.format('YYYY-MM-DD')}.zip`;
        // fileLink.setAttribute('download', `${filename}.${extension}`);
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo generado', 'success');
        this.loading.descargaGeneracion = false;

      } catch (error) {
        this.notifyVue('Error al generar archivo', 'danger');
        console.log(error);
        this.loading.descargaGeneracion = false;
      }
    },


    async pedirRadiacionPortafolio(){
      let datos = this.fechaDescargaGeneracion;
      if(datos === null || datos[0]===null || datos[1]===null){
        this.notifyVue('Fechas no pueden quedar vacías', 'danger');
        return;
      }

      let portafolio = this.portafolio;
      let fecha_inicio = dayjs(datos[0]).startOf('day');
      let fecha_fin = dayjs(datos[1]).endOf('day');
      try {
        this.notifyVue('Generando archivo comprimido', 'info');
        this.loading.descargaRadiacion = true;
        let res = await this.$http({
          method: 'POST',
          url: '/api/irradiacion/portafolio/descarga',
          data: {
            fecha_inicio: fecha_inicio.unix(),
            fecha_fin: fecha_fin.unix(),
            portafolio: portafolio.codigo
          },
          responseType: 'blob',
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        let filename = `Radiacion ${this.portafolio.nombre} - ${fecha_inicio.format('YYYY-MM-DD')} - ${fecha_fin.format('YYYY-MM-DD')}.zip`;
        // fileLink.setAttribute('download', `${filename}.${extension}`);
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo generado', 'success');
        this.loading.descargaRadiacion = false;

      } catch (error) {
        this.notifyVue('Error al generar archivo', 'danger');
        console.log(error);
        this.loading.descargaRadiacion = false;
      }
    },

    async pedirBitacoras(){
      let fecha_inicio = this.mes_dashboard.format('YYYY-MM-DD');
      let fecha_fin = this.mes_dashboard.endOf('month').format('YYYY-MM-DD');

      let portafolio = this.portafolio;
      try {
        this.notifyVue('Generando archivo de bitácoras', 'info');
        this.loading.descargaBitacoras = true;
        let res = await this.$http({
          method: 'POST',
          url: '/api/bitacoras/descarga',
          data: {
            fecha_inicio,
            fecha_fin,
            portafolio: portafolio.codigo
          },
          responseType: 'blob',
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        let filename = `Bitacoras ${this.portafolio.nombre} - ${this.mes_dashboard.format('YYYY-MM')}.xlsx`;
        // fileLink.setAttribute('download', `${filename}.${extension}`);
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo generado', 'success');
        this.loading.descargaBitacoras = false;

      } catch (error) {
        this.notifyVue('Error al generar archivo', 'danger');
        console.log(error);
        this.loading.descargaBitacoras = false;
      }
    },

    // Recibe un numero y retorna un string
    // con la clase a asignar
    // n: Numero
    estiloDisponbilidad(n){
      if(!n){
        return {}
      }

      if(n==100){
        return {'color': 'blue !important'}
      }
      else if(n>=97 && n < 100){
        return {'color': '#d4b726 !important'}
      }
      else{
        return {'color': 'red !important'}
      }
    },

    // Asigna el estilo de una celda de PR
    // n: Numero
    // esperado: objeto con numero esperado en 'pr'
    estiloPR(n, esperado){
      if(!esperado || !n){
        return {}
      }

      if(n>100){
        return {'color': 'red !important'}
      }

      if(n > esperado['pr']){
        return {'color': 'blue !important'}
      }
      else if(n>=esperado['pr_garantizado'] && n <= esperado['pr']){
        return {'color': '#d4b726 !important'}
      }
      else {
        return {'color': 'red !important'}
      }

      // let porc = n/esperado['pr'];

      // if(porc>1){
      //   return {'color': 'blue !important'}
      // }
      // else if(porc>=0.97 && porc <= 1){
      //   return {'color': '#d4b726 !important'}
      // }
      // else {
      //   return {'color': 'red !important'}
      // }
    },

    generarGraficos(){
      this.generarGraficoPR();
      this.generarGraficoDisp();
      this.generarGraficoGen();
      this.generarGraficoIrr();
      this.generarGraficoMensual();
      this.generarGraficoIrrMensual();
    },

    generarGraficoPR(){
      let data = [];
      let labels = [];
      let prOm = [];
      let prEsperado = [];
      let colores = [];

      this.plantas.forEach(planta => {
        if(planta.id in this.pvsols && this.pvsols[planta.id]){
          data = data.concat({
            nombre: planta.nombre,
            dataReal: planta.datos_dashboard.pr_om_acum,
            dataEsperada: this.pvsols[planta.id]['pr']
          })
        }
        else {
          data = data.concat({
            nombre: planta.nombre,
            dataReal: planta.datos_dashboard.pr_om_acum,
            dataEsperada: 0
          })
        }
      })

      if(this.sorted){
        data = data.sort((a,b) => (a.dataEsperada < b.dataEsperada) ? 1 : ((b.dataEsperada < a.dataEsperada) ? -1 : 0))
      }

      data.forEach(item => {
        labels.push(item.nombre);
        prOm.push(item.dataReal.toFixed(1));
        prEsperado.push(item.dataEsperada.toFixed(1));

        let color = item.dataReal < item.dataEsperada ? 'red' : '#4472C4';
        colores.push(color);
      });

      this.graficoPRData = {
        labels: labels,
        datasets: [
          {
            label: "PR O&M",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: colores,
            borderWidth: 2,
            pointRadius: 0,
            data: prOm,
            backgroundColor: colores,
          },
          {
            label: "PR PVSOL",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: prEsperado,
            backgroundColor: "#ED7D31",
          },
        ]
      }
    },

    generarGraficoDisp(){
      let labels = [];
      let dispReal = [];
      let dispOm = [];
      let dispEsperada = [];
      let coloresReal = [];
      let coloresOM = [];

      this.plantas.forEach(planta => {
        labels = labels.concat(planta.nombre);
        dispReal = dispReal.concat(planta.datos_dashboard.disp_real_acum);
        dispOm = dispOm.concat(planta.datos_dashboard.disp_om_acum);
        dispEsperada = dispEsperada.concat(97);

        let colorReal = planta.datos_dashboard.disp_real_acum < 97 ? 'red' : '#4472C4';
        let colorOM = planta.datos_dashboard.disp_om_acum < 97 ? 'red' : '#ED7D31';
        
        coloresReal.push(colorReal);
        coloresOM.push(colorOM);
      })

      this.graficoDispData = {
        labels: labels,
        datasets: [
          {
            label: "Disp. Esperada (97%)",
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: 'red',
            borderWidth: 2,
            pointRadius: 0,
            data: dispEsperada,
            backgroundColor: "red",
          },
          {
            label: "Disp. Real",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: coloresReal,
            borderWidth: 2,
            pointRadius: 0,
            data: dispReal,
            backgroundColor: coloresReal,
          },
          {
            label: "Disp. O&M",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: coloresOM,
            borderWidth: 2,
            pointRadius: 0,
            data: dispOm,
            backgroundColor: coloresOM,
          },
        ]
      }
    },

    generarGraficoIrrMensual(){
      let labels = [];
      let radiacion_diaria = [];
      let radiacion_promedio_esperada = [];
      let colores_radiacion_diaria = [];
      let datos_mensuales = this.datos_mensuales;
      const {radiacion_esperada_mensual, radiacion_mensual} = datos_mensuales;

      this.array_fechas.forEach(dia => {
        labels.push(dia.date());
        radiacion_diaria.push(radiacion_mensual[dia.date()]);
        radiacion_promedio_esperada.push(radiacion_esperada_mensual);

        // let colorReal = planta.datos_dashboard.disp_real_acum < 97 ? 'red' : '#4472C4';
        let colorReal = '#4472C4';
        
        colores_radiacion_diaria.push(colorReal);
      })

      this.graficoMensualIrrData = {
        labels: labels,
        datasets: [
          {
            label: "Rad. Promedio Esperada",
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: 'orange',
            borderWidth: 2,
            pointRadius: 0,
            data: radiacion_promedio_esperada,
            backgroundColor: "orange",
            borderDash: [50, 10],
          },
          {
            label: "Rad. Diaria",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: colores_radiacion_diaria,
            borderWidth: 2,
            pointRadius: 0,
            data: radiacion_diaria,
            backgroundColor: colores_radiacion_diaria,
          },
        ]
      }
    },

    generarGraficoMensual(){
      let labels = [];
      let generacion_diaria = [];
      let generacion_promedio_esperada = [];
      let colores_generacion_diaria = [];
      let datos_mensuales = this.datos_mensuales;
      const {generacion_esperada_mensual, generacion_mensual} = datos_mensuales;

      this.array_fechas.forEach(dia => {
        labels.push(dia.date());
        generacion_diaria.push(generacion_mensual[dia.date()]);
        generacion_promedio_esperada.push(generacion_esperada_mensual);

        // let colorReal = planta.datos_dashboard.disp_real_acum < 97 ? 'red' : '#4472C4';
        let colorReal = '#4472C4';
        
        colores_generacion_diaria.push(colorReal);
      })

      this.graficoMensualData = {
        labels: labels,
        datasets: [
          {
            label: "Gen. Promedio Esperada",
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: 'orange',
            borderWidth: 2,
            pointRadius: 0,
            data: generacion_promedio_esperada,
            backgroundColor: "orange",
            borderDash: [50, 10],
          },
          {
            label: "Gen. Diaria",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: colores_generacion_diaria,
            borderWidth: 2,
            pointRadius: 0,
            data: generacion_diaria,
            backgroundColor: colores_generacion_diaria,
          },
        ]
      }
    },

    generarGraficoGen(){
      let fecha_actual = dayjs();
      // Dias hasta el día anterior
      let dias_avanzados = fecha_actual.date()-1;

      let data = [];
      let dataEsperada = [];
      let dataReal = [];
      let labels = [];
      let colores = [];
      let bordes = [];
      let dataInyeccion = [];
      let dataAutoconsumo = [];

      this.plantas.forEach(planta => {
        if(planta.id in this.pvsols && this.pvsols[planta.id]){
          if(fecha_actual.isSame(this.mes_dashboard, 'month')){
            let proporcional = dias_avanzados*this.pvsols[planta.id]['gen']/this.dias_mes;
            // dataEsperada = dataEsperada.concat(proporcional);
            let dataInyeccion = 0;
            let dataAutoconsumo = 0;

            if(planta.tiene_smart_meter && planta.datos_dashboard['inyeccion_acum']){
              dataInyeccion = planta.datos_dashboard['inyeccion_acum'];
            }

            if(planta.tiene_smart_meter && planta.datos_dashboard['autoconsumo_acum']){
              dataAutoconsumo = planta.datos_dashboard['autoconsumo_acum'];
            }

            data = data.concat({
              nombre: planta.nombre,
              tiene_smart_meter: planta.tiene_smart_meter,
              dataReal: planta.datos_dashboard.gen_acum,
              dataEsperada: proporcional,
              dataInyeccion,
              dataAutoconsumo
            })
          }
          else{
            data = data.concat({
              nombre: planta.nombre,
              dataReal: planta.datos_dashboard.gen_acum,
              dataEsperada: this.pvsols[planta.id]['gen']
            })
          }
        }
        else {
          data = data.concat({
            nombre: planta.nombre,
            dataReal: planta.datos_dashboard.gen_acum,
            dataEsperada: 0
          })
        }
      })

      if(this.sorted){
        data = data.sort((a,b) => (a.dataEsperada < b.dataEsperada) ? 1 : ((b.dataEsperada < a.dataEsperada) ? -1 : 0))
      }

      data.forEach(item => {
        labels.push(item.nombre);
        dataEsperada.push(item.dataEsperada.toFixed(1));

        if(item.tiene_smart_meter){
          if(item.dataAutoconsumo > 0 || item.dataInyeccion > 0){
            if(item.dataReal > 0){
              dataReal.push(item.dataReal.toFixed(1));
              dataAutoconsumo.push(0);
              dataInyeccion.push(0);
            }
            else{
              dataReal.push(0);
              dataAutoconsumo.push(item.dataAutoconsumo.toFixed(1));
              dataInyeccion.push(item.dataInyeccion.toFixed(1));
            }
            
          }
          else{
            dataReal.push(item.dataReal.toFixed(1));
            dataAutoconsumo.push(item.dataAutoconsumo.toFixed(1));
            dataInyeccion.push(item.dataInyeccion.toFixed(1));
          }
          
        }
        else{
          dataAutoconsumo.push(0);
          dataInyeccion.push(0);
          dataReal.push(item.dataReal.toFixed(1));
        }

        let color = item.dataReal < item.dataEsperada ? 'red' : '#4472C4';
        colores.push(color);
        bordes.push(color);
      });

      this.graficoGenData = {
        labels: labels,
        datasets: [
          {
            label: "Generación",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            stacked: true,
            borderColor: colores,
            borderWidth: 2,
            pointRadius: 0,
            data: dataReal,
            stack: 'A',
            backgroundColor: colores,
          },
          {
            label: "Generación Simulada",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataEsperada,
            stack: 'B',
            backgroundColor: "#ED7D31",
          },
          {
            label: "Inyectado",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: bordes,
            borderWidth: 2,
            pointRadius: 0,
            data: dataInyeccion,
            stack: 'A',
            backgroundColor: "green",
          },
          {
            label: "Consumo",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: bordes,
            borderWidth: 2,
            pointRadius: 0,
            data: dataAutoconsumo,
            stack: 'A',
            backgroundColor: "blue",
          },
        ]
      }
    },

    generarGraficoIrr(){
      let fecha_actual = dayjs();
      // Dias hasta el día anterior
      let dias_avanzados = fecha_actual.date()-1;

      let data = [];
      let dataEsperada = [];
      let dataReal = [];
      let labels = [];
      let colores = [];

      this.plantas.forEach(planta => {
        // dataReal = dataReal.concat(planta.datos_dashboard.irr_acum)
        // labels = labels.concat(planta.nombre);

        if(planta.id in this.pvsols && this.pvsols[planta.id]){
          if(fecha_actual.isSame(this.mes_dashboard, 'month')){
            let proporcional = dias_avanzados*this.pvsols[planta.id]['irr']/this.dias_mes;
            // dataEsperada = dataEsperada.concat(proporcional);

            data = data.concat({
              nombre: planta.nombre,
              dataReal: planta.datos_dashboard.irr_acum,
              dataEsperada: proporcional
            })
          }
          else{
            // dataEsperada = dataEsperada.concat(this.pvsols[planta.id]['irr']);
            data = data.concat({
              nombre: planta.nombre,
              dataReal: planta.datos_dashboard.irr_acum,
              dataEsperada: this.pvsols[planta.id]['irr']
            })
          }
        }
        else {
          // dataEsperada = dataEsperada.concat(0);
          data = data.concat({
            nombre: planta.nombre,
            dataReal: planta.datos_dashboard.irr_acum,
            dataEsperada: 0
          })
        }
      })

      if(this.sorted){
        data = data.sort((a,b) => (a.dataEsperada < b.dataEsperada) ? 1 : ((b.dataEsperada < a.dataEsperada) ? -1 : 0))
      }

      data.forEach(item => {
        labels.push(item.nombre);
        dataReal.push(item.dataReal.toFixed(1));
        dataEsperada.push(item.dataEsperada.toFixed(1));

        let color = item.dataReal < item.dataEsperada ? 'red' : '#4472C4';
        colores.push(color);
      });

      this.graficoIrrData = {
        labels: labels,
        datasets: [
          {
            label: "Irradiación",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: colores,
            borderWidth: 2,
            pointRadius: 0,
            data: dataReal,
            backgroundColor: colores,
          },
          {
            label: "Irradiación Simulada",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataEsperada,
            backgroundColor: "#ED7D31",
          },
        ]
      }
    },

    async pedirDatosPvsol(fecha){
      try {
        let res_pvsol = await this.$http({
          method: 'GET',
          url: '/api/pvsol/mensual',
          params: {
            portafolio: this.portafolio.codigo,
            mes: fecha.month()+1,
            ano: fecha.year()
          }
        });

        this.pvsols = res_pvsol.data;
      } catch (error) {
        this.notifyVue('Error al cargar datos simulados', 'warning');
        console.log(error);
      }
    },

    async pedirDatosDashboard(fecha){
      try {
        let res = await this.$http({
          method: 'GET',
          url: '/api/dashboard',
          params: {
            portafolio: this.portafolio.codigo,
            inicio_acc: fecha.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          }
        });

        res.data.forEach(item => {
          item.ultima_fecha = dayjs(item.ultima_fecha);
        })
        this.plantas = res.data;
      } catch (error) {
        this.notifyVue('Error al cargar datos del dashboard', 'warning');
        console.log(error);
      }
    },

    async pedirDatosLimpieza(fecha){
      try {
        let res_limpieza = await this.$http({
          method: 'GET',
          url: '/api/limpieza',
          params: {
            portafolio: this.portafolio.codigo,
            fecha: fecha.format('YYYY-MM-DD'),
          }
        });

        let dias_limpieza = res_limpieza.data;
        Object.keys(dias_limpieza).map(function(planta, index) {
          dias_limpieza[planta].map(dias => {
            dias.dia = dayjs(dias.fecha).date();
          })
        });

        this.dias_limpieza = dias_limpieza;
      } catch (error) {
        this.notifyVue('Error al cargar datos de limpieza', 'warning');
        console.log(error);
      }
    },

    async pedirDatosBitacoras(fecha, fecha_fin){
      try {
        let res_limpieza = await this.$http({
          method: 'GET',
          url: '/api/bitacora',
          params: {
            portafolio: this.portafolio.codigo,
            fecha: fecha.format('YYYY-MM-DD'),
            fecha_fin: fecha_fin.format('YYYY-MM-DD')
          }
        });

        let bitacoras = res_limpieza.data;
        // Object.keys(bitacoras).map(function(planta, index) {
        //   bitacoras[planta].map(dias => {
        //     dias.dia = dayjs(dias.fecha).date();
        //   })
        // });

        this.bitacoras = bitacoras;
        this.loading.bitacoras = false;
      } catch (error) {
        this.notifyVue('Error al cargar datos de limpieza', 'warning');
        console.log(error);
      }
    },

    async pedirGeneracionMensual(fecha, fecha_fin){
      try {
        this.loading.gen_mensual = true;
        let res = await this.$http({
          method: 'GET',
          url: '/api/generacion/mensual',
          params: {
            portafolio: this.portafolio.codigo,
            fecha: fecha.format('YYYY-MM-DD'),
            fecha_fin: fecha_fin.format('YYYY-MM-DD')
          }
        });

        this.generacion_mensual = res.data;
        this.loading.gen_mensual = false;
        // this.loading.bitacoras = false;
      } catch (error) {
        this.notifyVue('Error al cargar generación mensual', 'warning');
        console.log(error);
      }
    },

    async pedirNombresInversores(id_planta){
      const portafolio = this.portafolio;

      if(portafolio.grupo === 'PMGD'){
        this.loading.nombres_inversores = true;
        try {
          // this.loading.gen_mensual = true;
          let res = await this.$http({
            method: 'GET',
            url: `/api/planta/${id_planta}/inversores/nombres`
          });

          this.heatmap_listado_nombres = res.data;
          this.heatmap_nombres_filtrados = res.data;
          this.loading.nombres_inversores = false;
        } catch (error) {
          this.notifyVue('Error al cargar nombres de inversores', 'warning');
          console.log(error);
          this.loading.nombres_inversores = false;
        }
      }
    },
    

    async pedirDatosHeatmap(fecha, fecha_fin){
      let portafolio = this.portafolio;
      if(portafolio.grupo === 'PMGD'){
        this.loading.heatmap = true;
        try {
          // this.loading.gen_mensual = true;
          let res = await this.$http({
            method: 'POST',
            url: '/api/heatmap/mensual',
            params: {
              portafolio: portafolio.codigo,
              planta: this.planta_seleccionada_heatmap,
              fecha: fecha.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              fecha_fin: fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
            },
            data:{
              inversores: this.heatmap_nombres_filtrados
            }
          });

          // this.generacion_mensual = res.data;
          // this.loading.gen_mensual = false;
          // this.series = res.data;
          this.heatmap_data = res.data;
          this.showHeatmap = true;
          this.loading.heatmap = false;
        } catch (error) {
          this.notifyVue('Error al cargar datos del Heatmap', 'warning');
          console.log(error);
          this.loading.heatmap = false;
        }
      }
    },

    peticionManualDatosHeatmap(){
      let fecha_inicio = this.mes_dashboard;
      let fecha_fin = this.mes_dashboard.endOf('month');
      this.pedirDatosHeatmap(fecha_inicio, fecha_fin);
    },

    async pedirDatosAnotaciones(){
      try {
        let res_anotaciones = await this.$http({
          method: 'GET',
          url: '/api/anotaciones',
          params: {
            portafolio: this.portafolio.codigo,
          }
        });

        let anotaciones = res_anotaciones.data;

        this.anotaciones = anotaciones;
        this.loading.anotaciones = false;
      } catch (error) {
        this.notifyVue('Error al cargar anotaciones', 'warning');
        console.log(error);
      }
    },

    abrirAnotaciones(planta_id, nombre){
      this.modalAnotaciones.show = true;
      this.modalAnotaciones.listado = this.anotaciones[planta_id];

      this.modalAnotaciones.planta = planta_id;
      this.modalAnotaciones.nombre = nombre;
    },

    async guardarAnotacionNueva(planta_id){
      let descripcion = this.anotacionNueva.descripcion;

      if(descripcion === ''){
        this.notifyVue('No pueden quedar campos vacíos', 'warning');
        return;
      }

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/anotaciones',
          data: {
            planta_id,
            descripcion,
          }
        });

        this.anotacionNueva.descripcion = '';
        this.notifyVue('Datos guardados', 'success');

        this.anotaciones[planta_id].push(res.data);
      } catch (error) {
        this.notifyVue('Error al guardar datos', 'danger');
        console.log(error);
      }
    },

    abrirEditarAnotacion(item){
      this.modalAnotacionEditar.show = true;
      this.modalAnotacionEditar.item = {...item};
    },

    async editarAnotacion(){
      let item = this.modalAnotacionEditar.item;
      let planta_id = this.modalAnotaciones.planta;

      try {
        let res = await this.$http({
          method: 'PUT',
          url: '/api/anotacion/'+item.id,
          data: {
            'descripcion': item.descripcion,
            'resuelto': item.resuelto
          }
        });

        this.notifyVue('Anotación actualizada', 'success');

        // Actualizar entrada del listado
        let listado_planta = this.anotaciones[planta_id].map(anotacion => {
          if(anotacion.id == item.id){
            return res.data;
          }
          return anotacion;
        });

        this.anotaciones[this.modalAnotaciones.planta] = listado_planta;
        this.modalAnotaciones.listado = listado_planta;

        this.modalAnotacionEditar.show = false;
      } catch (error) {
        this.notifyVue('Error al guardar datos', 'danger');
        console.log(error);
      }
    },

    cancelarEditarAnotacion(){
      this.modalAnotacionEditar.show = false;
      this.modalAnotacionEditar.item = {
        id: null,
        descripcion: '',
      };
    },

    abrirEliminarAnotacion(item){
      this.modalAnotacionEliminar.show = true;
      this.modalAnotacionEliminar.item = item;
    },

    async confirmarEliminarAnotacion(){
      let item = this.modalAnotacionEliminar.item;

      try {
        let res = await this.$http({
          method: 'DELETE',
          url: '/api/anotacion/'+item.id,
        });

        this.notifyVue('Anotación eliminada', 'success');

        // Filtrar entrada del listado
        let listado_planta = this.anotaciones[this.modalAnotaciones.planta].filter(entrada => item.id != entrada.id);
        this.anotaciones[this.modalAnotaciones.planta] = listado_planta;
        this.modalAnotaciones.listado = listado_planta;

        this.modalAnotacionEliminar.show = false;
        this.modalAnotacionEliminar.item = null;

      } catch (error) {
        this.notifyVue('Error al guardar datos', 'danger');
        console.log(error);
      }
    },

    cancelarEliminarAnotacion(item){
      this.modalAnotacionEliminar.show = false;
      this.modalAnotacionEliminar.item = null;
    },


    abrirResolverAnotacion(item){
      this.modalAnotacionResolver.show = true;
      this.modalAnotacionResolver.item = item;
    },

    async confirmarResolverAnotacion(){
      let item = this.modalAnotacionResolver.item;

      try {
        let res = await this.$http({
          method: 'PUT',
          url: '/api/anotacion/'+item.id,
          data: {
            'descripcion': item.descripcion,
            'resuelto': true
          }
        });

        this.notifyVue('Anotación resuelta', 'success');

        // Filtrar entrada del listado
        let listado_planta = this.anotaciones[this.modalAnotaciones.planta].filter(entrada => item.id != entrada.id);
        this.anotaciones[this.modalAnotaciones.planta] = listado_planta;
        this.modalAnotaciones.listado = listado_planta;

        this.modalAnotacionResolver.show = false;
        this.modalAnotacionResolver.item = null;

      } catch (error) {
        this.notifyVue('Error al resolver anotación', 'danger');
        console.log(error);
      }
    },

    cancelarResolverAnotacion(item){
      this.modalAnotacionResolver.show = false;
      this.modalAnotacionResolver.item = null;
    },


    abrirBitacora(planta_id, nombre){
      this.modalBitacora.show = true;
      this.modalBitacora.listado = this.bitacoras[planta_id];

      this.modalBitacora.planta = planta_id;
      this.modalBitacora.nombre = nombre;
    },

    async guardarBitacoraNueva(planta_id){
      let descripcion = this.bitacoraNuevo.descripcion;
      let fecha = this.bitacoraNuevo.fecha[0];
      let fecha_fin = this.bitacoraNuevo.fecha[1];
      let aplica_ot = this.bitacoraNuevo.aplica_ot;
      let n_ot = this.bitacoraNuevo.n_ot;
      let tipo = this.bitacoraNuevo.tipo;
      let evento = this.bitacoraNuevo.evento;
      let falla = this.bitacoraNuevo.falla === "true";
      let horas_desconexion = this.bitacoraNuevo.horas_desconexion;
      let energia_perdida = this.bitacoraNuevo.energia_perdida;


      if((tipo==='LIMPIEZA' || tipo==='CORTE') && (n_ot === null || n_ot === 0)){
        this.notifyVue('Se debe ingresar N° de OT para Limpieza y Corte', 'warning');
        return;
      }

      if(tipo==='DESVIACION' && descripcion===''){
        this.notifyVue('Se debe ingresar un comentario para Desviación', 'warning');
        return;
      }

      if(fecha === null || fecha_fin === null){
        this.notifyVue('No pueden quedar campos vacíos', 'warning');
        return;
      }

      if(aplica_ot && (n_ot === null || n_ot===0)){
        this.notifyVue('Ingresar N° de OT', 'warning');
        return;
      }

      if(horas_desconexion < 0){
        this.notifyVue('Horas de desconexión no puede ser negativo', 'warning');
        return;
      }

      if(energia_perdida < 0){
        this.notifyVue('Horas de desconexión no puede ser negativo', 'warning');
        return;
      }

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/bitacora',
          data: {
            planta_id,
            descripcion,
            fecha,
            fecha_fin,
            aplica_ot,
            n_ot,
            tipo,
            evento,
            falla,
            horas_desconexion,
            energia_perdida
          }
        });

        this.bitacoraNuevo.descripcion = '';
        this.bitacoraNuevo.fecha = [null, null];
        this.bitacoraNuevo.aplica_ot = false;
        this.bitacoraNuevo.n_ot = null;
        this.bitacoraNuevo.tipo = 'LIMPIEZA';
        this.bitacoraNuevo.evento = 'DESCONOCIDO';
        this.bitacoraNuevo.falla = false;
        this.bitacoraNuevo.horas_desconexion = 0;
        this.bitacoraNuevo.energia_perdida = 0;
        this.tipo = 'OT'
        this.notifyVue('Datos guardados', 'success');

        this.bitacoras[planta_id].push(res.data);
        // this.modalBitacora.listado.push(res.data);
      } catch (error) {
        this.notifyVue('Error al guardar datos', 'danger');
        console.log(error);
      }
    },

    abrirEliminarBitacora(item){
      this.modalBitacoraEliminar.show = true;
      this.modalBitacoraEliminar.item = item;
    },

    async confirmarEliminarBitacora(){
      let item = this.modalBitacoraEliminar.item;

      try {
        let res = await this.$http({
          method: 'DELETE',
          url: '/api/bitacora/'+item.id,
        });

        this.notifyVue('Entrada eliminada', 'success');

        // Filtrar entrada del listado
        let listado_planta = this.bitacoras[this.modalBitacora.planta].filter(entrada => item.id != entrada.id);
        this.bitacoras[this.modalBitacora.planta] = listado_planta;
        this.modalBitacora.listado = listado_planta;

        this.modalBitacoraEliminar.show = false;
        this.modalBitacoraEliminar.item = null;

      } catch (error) {
        this.notifyVue('Error al guardar datos', 'danger');
        console.log(error);
      }
    },

    cancelarEliminarBitacora(item){
      this.modalBitacoraEliminar.show = false;
      this.modalBitacoraEliminar.item = null;
    },

    abrirEditarBitacora(item){
      this.modalBitacoraEditar.show = true;
      this.modalBitacoraEditar.item = {...item};
      this.modalBitacoraEditar.item.fecha = [
        this.modalBitacoraEditar.item.fecha,
        this.modalBitacoraEditar.item.fecha_fin
      ]
    },

    async editarBitacora(){
      let item = this.modalBitacoraEditar.item;
      let descripcion = item.descripcion;
      let planta_id = this.modalBitacora.planta;
      let fecha = item.fecha[0];
      let fecha_fin = item.fecha[1];
      let aplica_ot = item.aplica_ot;
      let n_ot = item.n_ot;
      let tipo = item.tipo;
      let evento = item.evento;
      let falla = item.falla === "true";
      let horas_desconexion = item.horas_desconexion;
      let energia_perdida = item.energia_perdida;

      if((tipo==='LIMPIEZA' || tipo==='CORTE') && (n_ot === null || n_ot === 0)){
        this.notifyVue('Se debe ingresar N° de OT para Limpieza y Corte', 'warning');
        return;
      }

      if(tipo==='DESVIACION' && descripcion===''){
        this.notifyVue('Se debe ingresar un comentario para Desviación', 'warning');
        return;
      }

      if(aplica_ot && (n_ot==0 || n_ot==="" || n_ot===null)){
        this.notifyVue('Se debe ingresar N° de OT', 'warning');
        return;
      }

      if(!aplica_ot && (n_ot===0 || n_ot==="")){
        n_ot = null;
      }

      if(horas_desconexion < 0){
        this.notifyVue('Horas de desconexión no puede ser negativo', 'warning');
        return;
      }

      if(energia_perdida < 0){
        this.notifyVue('Horas de desconexión no puede ser negativo', 'warning');
        return;
      }

      try {
        let res = await this.$http({
          method: 'PUT',
          url: '/api/bitacora/'+item.id,
          data: {
            fecha,
            fecha_fin,
            descripcion,
            aplica_ot,
            n_ot,
            tipo,
            evento,
            falla,
            horas_desconexion,
            energia_perdida
          }
        });

        this.notifyVue('Entrada actualizada', 'success');

        // Actualizar entrada del listado
        let listado_planta = this.bitacoras[planta_id].map(entrada => {
          if(entrada.id == item.id){
            return res.data;
          }
          return entrada;
        });
        this.bitacoras[this.modalBitacora.planta] = listado_planta;
        this.modalBitacora.listado = listado_planta;

        this.modalBitacoraEditar.show = false;
        this.modalBitacoraEditar.item = {};
        // this.modalBitacoraEliminar.item = {
        //   id: null,
        //   descripcion: '',
        //   fecha: ''
        // };

      } catch (error) {
        this.notifyVue('Error al guardar datos', 'danger');
        console.log(error);
      }
    },

    cancelarEditarBitacora(){
      this.modalBitacoraEditar.show = false;
      this.modalBitacoraEditar.item = {
        id: null,
        descripcion: '',
        fecha: ''
      };
    },

    generarColorCuadro(valor){
      // let hue = Math.floor((100-valor)*120/100);
      // let saturation = Math.abs(valor-50);
      // let color = `hsl(${hue} ${saturation}% 50%)`;

      let hue = Math.floor(valor)
      
      return `hsl(${hue} 90% 50%)`;
    },

    async plantaSeleccionadaHeatmap(planta){
      const fecha_inicio = this.mes_dashboard;
      const fecha_fin = this.mes_dashboard.endOf('month');

      try {
        await this.pedirNombresInversores(planta);
        this.pedirDatosHeatmap(fecha_inicio, fecha_fin);
      } catch (error) {
        this.notifyVue('Error al cargar datos de heatmap', 'warning');
        console.log(error);
      }
    },
  },
  computed: {
    widthGraficoWrapper(){
      let n = this.plantas.length;
      const grafico_activo = this.graficoActivo;
      const subgrafico_activo = this.subGraficoActivo;
      
      if(subgrafico_activo === 'mensual'){
        return '';
      }

      if(n < 50){
        return '';
      }
      else {
        return n*50+'px'
      }
    },
    plantasPrMenorEstimado(){
      let pvsols = this.pvsols;

      return this.plantas.filter(planta => {
        if(pvsols[planta.id]){
          return planta.datos_dashboard.pr_om_acum <  pvsols[planta.id].pr
        }
      });
    },
    plantasDispMenor95(){
      return this.plantas.filter(planta => {
        return planta.datos_dashboard.disp_real_acum <  97
      });
    }
  },
  created(){
    this.hoy = dayjs();
  },
  async mounted(){
    try {
      let portafolio_codigo = this.$route.query.portafolio;

      let res_portafolio = await this.$http({
        method: 'GET',
        url: '/api/portafolio/'+portafolio_codigo,
      });

      this.portafolio = res_portafolio.data;

      // this.portafolio = this.portafolios.find(portafolio => portafolio.codigo==portafolio_codigo);

      let dia_anterior = dayjs().endOf('day').subtract(1, 'day').startOf('day');
      let inicio_acc = dia_anterior.startOf('month');
      this.fecha_dashboard = inicio_acc.format('YYYY-MM')

      let mes_dashboard = dia_anterior.startOf('month');
      let inicio_mes = mes_dashboard.startOf('month');
      let fin_mes = mes_dashboard.endOf('month');

      let temp_date = inicio_mes;
      while(temp_date.isBefore(fin_mes)) {
        this.array_fechas.push(temp_date);
        temp_date = temp_date.add(1, 'day');
      }

      this.dias_mes = fin_mes.diff(inicio_mes, 'day')+1;
      this.mes_dashboard = mes_dashboard;

      this.mismoMes = this.hoy.isSame(this.mes_dashboard, 'month');

      console.log('Pidiendo datos PVSOL');
      let r1 = this.pedirDatosPvsol(inicio_acc);

      // Pedir datos del dashboard
      console.log('Pidiendo datos del dashboard');
      let r2 = this.pedirDatosDashboard(inicio_acc);

      // console.log('Pidiendo datos de limpieza');
      // this.pedirDatosLimpieza(inicio_acc);

      await r1;
      await r2;

      let res_mensual = await this.$http({
        method: 'GET',
        url: `/api/dashboard/portafolio/${this.portafolio.codigo}/mensual`,
        params: {
          ano: inicio_acc.year(),
          mes: inicio_acc.month() + 1
        }
      });

      this.datos_mensuales = res_mensual.data;


      console.log('Generar gráficos');
      this.generarGraficos();

      console.log('Pedir datos de bitacoras');
      this.pedirDatosBitacoras(inicio_acc, fin_mes);

      console.log('Pedir datos de anotaciones');
      this.pedirDatosAnotaciones();

      // pedir generacion mensual
      this.pedirGeneracionMensual(inicio_acc, fin_mes);

      // decidir si mostrar heatmap
      if(this.portafolio.grupo === 'PMGD'){
        this.showHeatmapBox = true;
      }

      // Pedir datos para el Heatmap
      // await this.pedirNombresInversores();
      // this.pedirDatosHeatmap(inicio_acc, fin_mes);
    } catch (error) {
      console.error(error);
    }
  }
};
</script>

<style lang="scss">

.tooltip-inner {
  border: 1px solid black;
  background: #000000c4;
  color: white;
  padding: 15px;
  border-radius: 10px;
  max-width: 300px;
}

.tooltip-circle {
  width: 10px;
  height: 10px;
  // background: red !important;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 10px;
}

.bitacora-limpieza {
  background: #037ed4
}

.bitacora-corte {
  background: #215b4d
}

.bitacora-desviacion {
  background: #b11134
}

</style>

<style lang="scss" scoped>

.table-dashboard {
  font-size: 11px;
  > tbody {
    .dashboard-first-line{
      border-top: solid #000 0.12rem;
    }

    > tr > td {
      padding: 4px 2px !important;
    }
  }
}

.table-sticky{
  max-height: 80vh;
  overflow: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);

}

.info-fecha {
  color: #808080;
  font-size: 10px;
  margin-bottom: 1px;
}

.info-errores {
  color: red;
}

.dia-limpieza {
  &-start {
    border: 2px solid red !important;
    border-right-width: 0 !important;
  }

  &-middle {
    border: 2px solid red !important;
    border-right-width: 0 !important;
    border-left-width: 0 !important;
  }

  &-end {
    border: 2px solid red !important;
    border-left-width: 0 !important;
  }

  &-start-end {
    border: 2px solid red !important;
  }
  
}

.chart-area {
  margin: 30px 0;
  border: solid 1px #60864c;
  padding: 5px
}

.chart-area-wrapper {
  width: 100%; overflow-x: auto; 

  > .chart-area-temp {
    // width: 3000px;
    height: 400px
  }
}

.td-acum {
  border-right: 2px solid black !important;
}

.btn-bitacora {
  font-size: 0.7rem;
  padding: 5px 10px;
  border-radius: 20px;
}


.bitacora-simbologia {
  // border: 2px solid darken(#fff, 5);
  // border-radius: 5px;
  padding: 10px;
  // background: darken(#fff, 2);
  max-height: 30vh;
  overflow: auto;
  display: flex;
  justify-content: space-around;

  > div {
    display: flex;
    align-items: center;

    > .cuadro {
      width: 15px;
      height: 15px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }

}

.bitacora-listado {
  border: 2px solid darken(#fff, 5);
  border-radius: 5px;
  padding: 10px;
  background: darken(#fff, 2);
  max-height: 30vh;
  overflow: auto;

  > .bitacora-item {
    border: 1px solid #fff;
    // background: #215b4d;
    color: white;
    margin: 5px 10px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .bitacora-item-contenido {
      display: flex;
      align-items: center;

      > .bitacora-item-fecha {
        margin: 0 5px;
        padding: 0 5px;
        font-weight: bold;
        border-right: 2px solid rgb(255 255 255 / 30%);
        font-size: 11px;
        min-width: 90px;
        max-width: 90px;
      }

      > .bitacora-item-texto {
        line-height: 20px;
        white-space: pre-line;
      }
    }

    > .bitacora-item-contenido-right {
      display: flex;

      > .bitacora-item-usuarios {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        line-height: 12px;
        padding: 0 5px;
        justify-content: center;
        min-width: 130px;
      }
      
      > .bitacora-item-botones {
        border-left: 2px solid rgb(255 255 255 / 30%);
        padding: 0 5px;
        min-width: 92px;

        > div {
          border-radius: 10px;
          margin: 0 5px;
          // background: darken(#215b4d, 7);
          background: rgb(255 255 255 / 10%);
          padding: 5px 10px;

          > i {
            font-size: 10px;
          }

          &:hover {
            // background: darken(#215b4d, 10) !important;
            background: rgb(255 255 255 / 10%) !important;
            color: #5DB134;
          }
        }
      }
    }
  }
}

.anotacion-listado {
  border: 2px solid darken(#fff, 5);
  border-radius: 5px;
  padding: 10px;
  background: darken(#fff, 2);
  max-height: 30vh;
  overflow: auto;

  > .anotacion-item {
    border: 1px solid #fff;
    background: #215b4d;
    color: white;
    margin: 5px 10px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .anotacion-item-contenido {
      display: flex;
      align-items: center;

      > .anotacion-item-fecha {
        margin: 0 5px;
        padding: 0 5px;
        font-weight: bold;
        border-right: 2px solid rgb(255 255 255 / 30%);
        font-size: 11px;
        min-width: 45px;
        max-width: 50px;
      }

      > .anotacion-item-texto {
        line-height: 20px;
        white-space: pre-line;
      }
    }

    > .anotacion-item-contenido-right {
      display: flex;

      > .anotacion-item-usuarios {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        line-height: 12px;
        padding: 0 5px;
        justify-content: center;
        min-width: 130px;
      }
      
      > .anotacion-item-botones {
        border-left: 2px solid rgb(255 255 255 / 30%);
        padding: 0 5px;
        min-width: 92px;

        > div {
          border-radius: 10px;
          margin: 0 5px;
          // background: darken(#215b4d, 7);
          background: rgb(255 255 255 / 10%);
          padding: 5px 10px;

          > i {
            font-size: 10px;
          }

          &:hover {
            // background: darken(#215b4d, 10) !important;
            background: rgb(255 255 255 / 10%) !important;
            color: #5DB134;
          }
        }
      }
    }
  }
}

.n-entradas {
  display: inline-block;
}

.datepicker-col {
  display: table;
}

.card-mes {
  text-align: center;
}

.listado-nombres-inversores {
  display: flex;
  justify-content: space-between;
}

.heat-general {
  display: flex;
  flex-direction: column;
}

.heat-content{
  display: flex;
  flex-direction: row;
  align-items: center;

  .heat-eje {
    display: inline;
    transform: rotate(-90deg);
    white-space: nowrap;
    
    span {
      font-size: 1.2rem;
    }
  }
  .heat-data { 
    width: 100%
  }
}

.heat-row {
  display: flex;
  justify-content: center;
  text-align: center;
}

.cuadro-fila {
  border: 1px solid white;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
}

.cuadro-heat {
  border: 1px solid white;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &:hover {
    filter: brightness(85%);
    cursor: pointer;
  }
}

.item-resumen {
  cursor: pointer;
  &:hover {
    background: darken(white, 5%);
  }
}

.recuadro-errores {
  height: 330px;
  min-height: 330px;
  max-height: 330px;
  overflow-y: scroll;
}
</style>