import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App.vue";

import router from "./router";
import store from "./store";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import './registerServiceWorker'
import VueMeta from 'vue-meta'

import PrimeVue from 'primevue/config';

import axios from 'axios';
import Chart from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.register(ChartDataLabels);
Chart.defaults.global.plugins.datalabels.display = false;

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const backend_db_instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_DB_URL,
});

backend_db_instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let originalConfig = error.config;
    console.log('error');
    console.log(error.response);
    if(error.response){
      if ((error.response.status === 401 || error.response.status === 403) && !originalConfig._retry) {
        originalConfig._retry = true;

        // Do something, call refreshToken() request for example;
        // return a request
        return axios_instance(config);
      }

      // if (error.response.status === ANOTHER_STATUS_CODE) {
      //   // Do something 
      //   return Promise.reject(error.response.data);
      // }
    }

    return Promise.reject(error);
  }
)

Vue.prototype.$http = backend_db_instance;
const token = localStorage.getItem('access_token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
// Vue.use(VueMeta, { keyName: 'head' })
Vue.use(VueMeta);
Vue.use(PrimeVue);

/* eslint-disable no-new */
new Vue({
  store: store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
