<template>
  <div>
    <div class="dropdown-header">
      <router-link
        :to="{ name: 'dashboard', query: { portafolio: portafolio.codigo }}"
      >
        <i class="tim-icons icon-chart-bar-32"></i>
        <p class="nombre-portafolio">{{portafolio.nombre}}</p>
      </router-link>
      <i v-if="!loading"
        class="fas"
        :class="isOpen ? 'fa-minus' : 'fa-plus'"
        style="font-size: 15px"
        @click="toggleDropdown"
        v-click-outside="closeDropdown"
        v-show="portafolio.plantas.length > 0"
      ></i>

      <i v-else
        class="fas fa-spinner fa-spin"
      ></i>

      <div class="cuadro-emergente" tabindex="-1" v-show="isOpen">
        <router-link
          :to="{ name: 'portafolio', params: { codigo: portafolio.codigo }}"
        >
          <p>{{portafolio.nombre}}</p>
        </router-link>

        <ul class="dropdown-items">
          <li v-for="planta in portafolio.plantas" :key="`${portafolio.codigo}_${planta.id}`">
            <router-link
              :to="{ name: 'planta', params: { id: planta.id }}"
            >
              <p>{{planta.nombre}}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    
  </div>
</template>

<script>

export default {
  name: "delta-dropdown",
  components: {
  },
  props: {
    portafolio: {
      type: Object,
      default: {nombre: '', codigo: '', plantas: []},
      description: "Objeto que representa un portafolio"
    },
    loading: {
      type: Boolean,
      default: true,
      description: 'Flag que indica si aun cargan los datos del portafolio'
    }
  },
  data(){
    return {
      isOpen: false
    }
  },
  methods: {
    toggleDropdown(){
      this.isOpen = !this.isOpen;
    },
    closeDropdown(){
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.dropdown-header {
  display: flex;
  justify-content: space-between;
  padding: 6px;

  > i:hover {
    color: #5DB134;
    cursor: pointer;
  }
}

.dropdown-items {
  padding-left: 15px;

  > li {
    list-style: disc;
    color: #fff;

    > a {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.cuadro-emergente {
  background: #0A335F;
  border-radius: 5px;
  position: fixed;
  z-index: 1100;
  outline: 0;
  top: 80px;
  margin: 0 240px;
  padding: 0.5rem 1.5rem;
  max-height: calc(100vh - 90px);
  height: calc(100vh - 90px);
  overflow: auto;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5)
}

.nombre-portafolio:hover {
  color: #5DB134;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>
